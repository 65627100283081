<template>
  <div class="m-2">
    <b-card class="mx-auto" style="max-width: 512px">
      <b-alert
        variant="danger"
        dismissible
        :show="error != ''"
        @dismissed="error = false"
        >{{ error }}</b-alert
      >

      <b-alert
        :show="info != ''"
        @dismissed="info = false"
        variant="secondary"
        >{{ info }}</b-alert
      >
      <div v-if="state == 'sending'">
        <b-card-text>
          <b-alert show class="text-center" variant="secondary">
            Sending reset password link...
            <b-spinner small></b-spinner>
          </b-alert>
        </b-card-text>
      </div>
      <div v-if="state == 'normal'">
        <b-form @submit.stop.prevent="sendResetPasswordLink()">
          <b-form-group>
            <b-input-group>
              <b-form-input
                placeholder="Email Address"
                v-model="form.emailAddress"
                aria-describedby="input.create.feedback"
                type="email"
                required
              ></b-form-input>

              <b-form-invalid-feedback id="input.create.feedback"
                >Please enter a valid email address.</b-form-invalid-feedback
              >
            </b-input-group>
          </b-form-group>

          <b-form-group class="text-center mb-0">
            <b-button variant="primary" type="submit"
              >Send Reset Password Link</b-button
            >
          </b-form-group>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "ResetPass",
  data: () => ({
    info: false,
    error: false,
    state: "normal",
    form: {
      emailAddress: "",
    },
  }),
  methods: {
    async sendResetPasswordLink() {
      this.error = false;
      this.info = false;
      this.state ="sending";

      const response = await API.sendResetPasswordEmail(this.form.emailAddress);

      if (response.error) {
        this.error = response.error
        this.state = "normal";
      } else {
        this.info = "Reset password link was sent. Please check your email."
        this.state = "normal";
      }
    },
  }
};
</script>