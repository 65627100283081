<template>
  <div class="m-2">
    <b-card class="mx-auto" style="max-width: 512px">
      <b-alert
        variant="danger"
        dismissible
        :show="error != ''"
        @dismissed="error = false"
        >{{ error }}</b-alert
      >

      <b-alert
        :show="info != ''"
        @dismissed="info = false"
        variant="secondary"
        >{{ info }}</b-alert
      >
    </b-card>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "ConfirmAccount",
  data: () => ({
    info: false,
    error: false
  }),
  async created() {
    const {token, tokenId} = this.$route.query;
    const response = await API.confirmAccount(token, tokenId);
    if (response.error) {
      this.error = response.error
    } else {
      this.info = "Confirmed email address."
    }
  },
};
</script>