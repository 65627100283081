<template>
  <div class="p-2 eu-login-with-password" @click="onBodyClick">
    <b-card class="mx-auto" style="max-width: 512px">
      <b-alert
        variant="danger"
        dismissible
        :show="error != ''"
        @dismissed="error = false"
        >{{ error }}</b-alert
      >

      <b-alert
        :show="info != ''"
        @dismissed="info = false"
        variant="secondary"
        >{{ info }}</b-alert
      >

      <div v-if="state == 'creating'">
        <b-card-text>
          <b-alert show class="text-center" variant="secondary">
            Creating account...
            <b-spinner small></b-spinner>
          </b-alert>
        </b-card-text>
      </div>

      <div v-if="state == 'authenticating'">
        <b-card-text>
          <b-alert show class="text-center" variant="secondary">
            Authenticating...
            <b-spinner small></b-spinner>
          </b-alert>
        </b-card-text>
      </div>

      <div v-if="state == 'normal'">
        <b-form @submit.stop.prevent="form.create === 'true' ? signUp() : signIn()">
          <b-form-group class="text-center">
            <b-form-radio-group
              v-model="form.create"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio value="false">Sign In</b-form-radio>
              <b-form-radio value="true">Create Account</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-form-input
                placeholder="Email Address"
                v-model="form.emailAddress"
                aria-describedby="input.create.feedback"
                type="email"
                required
              ></b-form-input>

              <b-form-invalid-feedback id="input.create.feedback"
                >Please enter a valid email address.</b-form-invalid-feedback
              >
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-form-input
                placeholder="Password"
                name="password"
                v-model="form.password"
                aria-describedby="input.create.feedback"
                type="password"
                @focus="onFocusPassword"
                @click="onPasswordClick"
                readonly
                class="eu-readonly-field-normalize"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-form-group v-if="form.create === 'true'">
            <b-input-group>
              <b-form-input
                name="confirmPassword"
                placeholder="Confirm Password"
                v-model="form.confirmPassword"
                @click="onPasswordClick"
                @focus="onFocusPassword"
                aria-describedby="input.create.feedback"
                type="password"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group class="text-center mb-0">
            <b-button variant="primary" type="submit"
              >{{form.create === 'true' ? 'Create' : 'Login'}}</b-button
            >
            <b-button class="ml-3" variant="danger" type="submit" v-if="form.create === 'false'" href="/reset-pass"
              >Reset Password</b-button
            >
          </b-form-group>
        </b-form>
        <numericpad
          :onInput="onInput"
          :onDelete="onDelete"
          :onReset="onReset"
          :show="showNumberPad"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/api";
import numericpad from "@/components/controls/numeric-keypad";

export default {
  name: "LoginWithPassword",
  components: {
    numericpad
  },
  data: () => ({
    info: false,
    error: false,
    showNumberPad: false,
    passwordFieldName: 'password',
    state: "normal",
    form: {
      create: "false",
      emailAddress: "",
      password: "",
      confirmPassword: "",
    },
  }),
  beforeCreate() {
    // Redirect to dashboard if authenticated.
    if (API.authenticated) {
      this.$router.push({ path: "/dashboard" });
    }
  },
  methods: {
    onFocusPassword() {
      this.showNumberPad = true;
    },
    onBodyClick() {
      this.showNumberPad = false;
    },
    onPasswordClick(event) {
      event.stopPropagation();
      this.passwordFieldName = event.target.name;
    },
    onInput(key) {
      this.form[this.passwordFieldName] = (this.form[this.passwordFieldName] + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.form[this.passwordFieldName] = this.form[this.passwordFieldName].slice(0, this.form[this.passwordFieldName].length - 1);
    },
    onReset() {
      this.form[this.passwordFieldName] = "";
    },
    async login(token) {
      this.error = false;
      this.info = false;
      this.state = "authenticating";

      const response = await API.login(token);

      if (response.error) {
        this.error = response.error;
        this.state = "normal";
        this.token = null;
      } else {
        this.$router.push(response.location);
      }
    },
    async signUp() {
      this.error = false;
      this.info = false;
      this.state ="creating";

      if (!/^\d+$/.test(this.form.password)) {
        this.error = 'Please use only numbers for password.';
        this.state = "normal";
        return;
      }
      if (this.form.password !== this.form.confirmPassword) {
        this.error = 'Please confirm password again. it doesn\'t match.';
        this.state = "normal";
        return;
      }
      if (this.form.password.length < 6) {
        this.error = 'Minimum length of password is 4.';
        this.state = "normal";
        return;
      }
      const response = await API.signUp(
        this.form.emailAddress,
        this.form.password
      );

      if (response.error) {
        this.error = response.error;
        this.state = "normal";
      } else {
        this.state = "normal";
        this.info = "Registered successfully. Please check your email.";
      }
    },
    async signIn() {
      this.error = false;
      this.info = false;
      this.state ="authenticating";

      const response = await API.signIn(
        this.form.emailAddress,
        this.form.password
      );

      if (response.error) {
        this.error = response.error;
        this.state = "normal";
      } else {
        this.$router.push(response.location);
      }
    },
  },
};
</script>