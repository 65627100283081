<template>
  <div class="m-2 eu-login-with-password">
    <b-alert
      variant="danger"
      dismissible
      :show="error != ''"
      @dismissed="error = false"
      >{{ error }}</b-alert
    >
    <b-alert
      :show="info != ''"
      @dismissed="info = false"
      variant="secondary"
      >{{ info }}</b-alert
    >
    <div v-if="state == 'resetting'">
      <b-card-text>
        <b-alert show class="text-center" variant="secondary">
          Resetting...
          <b-spinner small></b-spinner>
        </b-alert>
      </b-card-text>
    </div>
    <div v-if="state == 'normal'">
      <b-form @submit.stop.prevent="reset()">
        <div class="eu-pin-code-input">
          <div class="dots">
            <div :key="n" :class="`dot ${password.length > index ? 'active' : ''}`" v-for="(n, index) in new Array(Math.min(password.length, 10))"></div>
          </div>
          <p>Enter the password</p>
          <div class="numbers">
            <div class="number" @click="handleNumberClick($event, 1)">1</div>
            <div class="number" @click="handleNumberClick($event, 2)">2</div>
            <div class="number" @click="handleNumberClick($event, 3)">3</div>
            <div class="number" @click="handleNumberClick($event, 4)">4</div>
            <div class="number" @click="handleNumberClick($event, 5)">5</div>
            <div class="number" @click="handleNumberClick($event, 6)">6</div>
            <div class="number" @click="handleNumberClick($event, 7)">7</div>
            <div class="number" @click="handleNumberClick($event, 8)">8</div>
            <div class="number" @click="handleNumberClick($event, 9)">9</div>
          </div>
        </div>
        <b-form-group class="text-center mt-3">
          <b-button variant="primary" type="submit" :disabled="password.length < 4"
            >Reset Password</b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  name: "ResetPassword",
  data: () => ({
    info: false,
    error: false,
    state: "normal",
    password: "",
  }),
  methods: {
    handleNumberClick(event, num) {
      event.target.className += ' grow';
      this.password += num;
      setTimeout(() => {
        event.target.className = 'number';
      }, 1000);
    },
    async reset() {
      this.error = false;
      this.info = false;
      this.state ="resetting";
      const {token} = this.$route.query;
      const response = await API.resetPassword(token, this.password);
      this.password = '';

      if (response.error) {
        this.error = response.error
        this.state = "normal";
      } else {
        this.info = "Password has been reset."
        this.state = "";
      }
    },
  }
};
</script>