<template>
  <div class="m-2">
    <b-card class="mx-auto" style="max-width: 512px">
      <b-alert
        variant="danger"
        dismissible
        :show="error != ''"
        @dismissed="error = false"
        >{{ error }}</b-alert
      >

      <b-alert
        :show="info != ''"
        @dismissed="info = false"
        variant="secondary"
        >{{ info }}</b-alert
      >

      <div v-if="state == 'sendingLink'">
        <b-card-text>
          <b-alert show class="text-center" variant="secondary">
            Sending magic link...
            <b-spinner small></b-spinner>
          </b-alert>
        </b-card-text>
      </div>

      <div v-if="state == 'authenticating'">
        <b-card-text>
          <b-alert show class="text-center" variant="secondary">
            Authenticating magic link...
            <b-spinner small></b-spinner>
          </b-alert>
        </b-card-text>
      </div>

      <div v-if="state == 'sendLink'">
        <b-form @submit.stop.prevent="sendLink()">
          <b-form-group class="text-center">
            <b-form-radio-group
              v-model="form.create"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio value="false">Sign In</b-form-radio>
              <b-form-radio value="true">Create Account</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-form-input
                placeholder="Email Address"
                v-model="form.emailAddress"
                aria-describedby="input.create.feedback"
                type="email"
                required
              ></b-form-input>

              <b-form-invalid-feedback id="input.create.feedback"
                >Please enter a valid email address.</b-form-invalid-feedback
              >
            </b-input-group>
          </b-form-group>

          <b-form-group class="text-center mb-0">
            <b-button variant="primary" type="submit"
              ><b-icon icon="envelope-fill"></b-icon> Send Link</b-button
            >
          </b-form-group>
        </b-form>

        <!-- {{ form }} -->
      </div>

      <!-- 
    <div v-if="state == 'sendLink'">
      <b-card-text>Send a temporary login link via email.</b-card-text>

      <b-form @submit.stop.prevent="sendLink(false)">
        <b-form-group>
          <b-form-input
            placeholder="Email Address"
            v-model="$v.form.emailAddress.$model"
            :state="validateState('emailAddress')"
            aria-describedby="input.email.feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input.email.feedback"
            >Please enter a valid email address.</b-form-invalid-feedback
          >
          <b-button type="submit" variant="primary" size="sm" class="mt-2">
            <b-icon icon="envelope-fill"></b-icon> Send Link
          </b-button>
        </b-form-group>
      </b-form>
    </div> -->
    </b-card>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "Login",
  data: () => ({
    info: false,
    error: false,
    state: "sendLink",
    form: {
      create: "false",
      emailAddress: "",
    },
  }),
  beforeCreate() {
    // Redirect to dashboard if authenticated.
    if (API.authenticated) {
      this.$router.push({ path: "/dashboard" });
    }
  },
  created() {
    // Login if we have a token.
    if (!API.authenticated && this.$route.params.token) {
      this.login(this.$route.params.token);
    }
  },
  methods: {
    async login(token) {
      this.error = false;
      this.info = false;
      this.state = "authenticating";

      const response = await API.login(token);

      if (response.error) {
        this.error = response.error;
        this.state = "sendLink";
        this.token = null;
      } else {
        this.$router.push(response.location);
      }
    },
    async sendLink() {
      this.error = false;
      this.info = false;
      this.state = "sendingLink";

      const response = await API.sendLink(
        this.form.emailAddress,
        this.form.create
      );

      if (response.error) {
        this.error = response.error;
        this.state = "sendLink";
      } else {
        this.state = "";
        this.info = response.info;
      }
    },
  },
};
</script>